import { Grid, Typography, Link } from "@mui/material";
import { Recruiter, startConversation } from "adapters";
import { AvatarItem, WButton } from "components";
import { WTypography } from "components/WTypography";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";

interface RecruiterCardProps {
  jobId: string;
  recruiter: string | Recruiter;
}

export const RecruiterCard: FC<RecruiterCardProps> = ({ jobId, recruiter }) => {
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);
  const { showModal, hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const history = useHistory();

  const submitConversation = () => {
    if (!isAuthenticated) {
      history.push("/login");
      hideModal();
      return;
    }

    startConversation(jobId)
      .then((res) => {
        if (!isDesktop) {
          showModal("MESSAGE_MODAL", { conversationId: res.data.conv_id });
        } else {
          history.push(`/messages/${res.data.conv_id}`);
        }
      })
      .catch(() => {
        //add proper message
        addMessage({ type: "error" });
      });
  };

  const recruiterIsObject = typeof recruiter === "object";

  return (
    <Grid container gap=".5rem">
      <Typography color="primary.main" fontWeight="bold">
        {localize("common.recruiter")}
      </Typography>
      <Grid container gap="1rem" wrap="nowrap">
        <AvatarItem
          size="small"
          imageSrc={
            (recruiterIsObject ? recruiter.profile_picture : null) ||
            `${process.env.PUBLIC_URL}/assets/logo/icon_64.png`
          }
          firstName={recruiterIsObject ? recruiter.email : recruiter}
        />
        <Grid container direction="column" gap="1rem">
          <Grid container direction="column" gap=".2rem">
            {recruiterIsObject && (
              <Grid container item gap=".3rem">
                <Typography
                  fontWeight="bold"
                  lineHeight="1rem"
                  sx={{ lineBreak: "anywhere" }}
                  variant="subtitle1"
                  color="primary.dark"
                >
                  {recruiter.first_name}
                </Typography>

                <Typography
                  fontWeight="bold"
                  lineHeight="1rem"
                  sx={{ lineBreak: "anywhere" }}
                  variant="subtitle1"
                  color="primary.dark"
                >
                  {recruiter.last_name}
                </Typography>
              </Grid>
            )}
            <WTypography
              lineClamps={1}
              fontWeight={recruiterIsObject ? "normal" : "bold"}
              lineHeight="1rem"
              variant="body2"
              color="primary.dark"
            >
              <Link href={`mailto:${recruiter}`} fontWeight={recruiterIsObject ? "normal" : "bold"}>
                {recruiterIsObject ? recruiter.email : recruiter}
              </Link>
            </WTypography>

            {recruiterIsObject && recruiter.phone ? (
              <Typography
                lineHeight="1rem"
                sx={{ lineBreak: "anywhere" }}
                variant="body2"
                color="primary.dark"
              >
                {recruiter.phone}
              </Typography>
            ) : null}
          </Grid>
          <WButton sx={{ maxWidth: "max-content" }} variant="outlined" onClick={submitConversation}>
            {localize("common.startConversation")}
          </WButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
